// require('./src/assets/grid.css');
require('./src/assets/reset.css');

const removeGatsbyScroll = location => {
  if (location && location.hash) {
    if (window.innerWidth >= 1024) {
      window.scrollBy(0, -80);
    } else {
      window.scrollBy(0, -64);
    }
    return;
  } else {
    window.scrollTo(0, 0);
  }
};

exports.onClientEntry = () => {
  window.sessionStorage.setItem('query_search', window.location.search);

  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.setAttribute('data-tabbing', 'true');
      window.removeEventListener('keydown', handleFirstTab);
    }
  }

  window.addEventListener('keydown', handleFirstTab);
};

exports.onRouteUpdate = ({ location }) => {
  removeGatsbyScroll(location);

  if (document.body.classList.contains('mobilemenu-open')) {
    document.body.classList.remove('mobilemenu-open');
  }
};
